export function debounce(callback, wait, immediate = false) {
  var timeout = null

  return function() {
    var callNow = immediate && !timeout
    var next = () => callback.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(next, wait)

    if (callNow) {
      next()
    }
  }
}

export default debounce