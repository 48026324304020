import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "nextButton", "prevButton"]

  connect() {
    this.currentIndex = 0

    if (this.hasContainerTarget) {
        // Select slides by accessing the child elements of containerTarget
        this.slides = Array.from(this.containerTarget.children)

      this.updateButtonVisibility()
    }
  }

  next() {
    if (this.currentIndex < this.slides.length - 1) {
      this.currentIndex++
      this.updateSlidePosition()
      this.updateButtonVisibility()
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.updateSlidePosition()
      this.updateButtonVisibility()
    }
  }

  updateSlidePosition() {
    const translatePercentage = -100 * this.currentIndex
    this.containerTarget.style.transform = `translateX(${translatePercentage}%)`
  }

  updateButtonVisibility() {
    if (this.currentIndex === 0) {
      this.prevButtonTarget.classList.add('opacity-0', 'pointer-events-none')
    } else {
      this.prevButtonTarget.classList.remove('opacity-0', 'pointer-events-none')
    }

    if (this.currentIndex === this.slides.length - 1) {
      this.nextButtonTarget.classList.add('opacity-0', 'pointer-events-none')
    } else {
      this.nextButtonTarget.classList.remove('opacity-0', 'pointer-events-none')
    }
  }
}