// app/frontend/controllers/add_to_cart_modal_controller.js
import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ["Modal", "ModalBgOverlay", "ModalContent", "QuantityInput", "SubmitButton"]
  static values = {
    listingId: String
  }

  onAddToCartModalButtonClick(e) {
    e.stopPropagation()
    e.preventDefault()

    this.ModalTarget.classList.remove('hidden', 'pointer-events-none')
    document.querySelector('html').style.overflow = 'hidden'
    enter(this.ModalBgOverlayTarget)
    enter(this.ModalContentTarget)

    setTimeout(() => this.QuantityInputTarget.focus(), 250)
  }

  onModalCloseClick(e) {
    e.stopPropagation()

    leave(this.ModalBgOverlayTarget)
    leave(this.ModalContentTarget)
    document.querySelector('html').style.overflow = ''
    setTimeout(() => this.ModalTarget.classList.add('hidden', 'pointer-events-none'), 300)
  }

  onSubmitClick(e) {
    const quantityValue = parseInt(this.QuantityInputTarget.value)

    if (!Number.isInteger(quantityValue) || quantityValue <= 0) {
      e.stopPropagation()
      e.preventDefault()
      this.QuantityInputTarget.classList.add("!border-red-500", "!border-4")
    }
  }

  onQuantityInput(e) {
    if (e.target.classList.contains("!border-red-500")) {
      e.target.classList.remove("!border-red-500", "!border-4")
    }
  }

  stopPropagation(e) {
    e.stopPropagation()
  }

}
