import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["element", "tooltip"]
  static values = {
    placement: { type: String, default: "bottom" },
    offset: { type: Array, default: [0, 8] },
  }

  connect() {
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offsetValue,
          },
        },
      ],
    })
  }

  show() {
    this.tooltipTarget.setAttribute("data-show", "")

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    this.popperInstance.update()
  }

  hide() {
    this.tooltipTarget.removeAttribute("data-show")
  }

  // Destroy the Popper instance
  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy()
    }
  }
}
