import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  get modalController() {
    const element = document.querySelector(`[data-controller="modal-hotwired"]`);
    return element ? element[`modal-hotwired`] : null;
  }

  get recaptchaV3Controller() {
    const element = document.querySelector(`[data-controller="recaptcha-v3"]`);
    return element ? element[`recaptcha-v3`] : null;
  }

  connect() {
    this.element[this.identifier] = this
  }

  onClick() {
    this.modalController.open()
    this.recaptchaV3Controller.executeRecaptcha()
  }

}