import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "image"]

  open(event) {
    event.preventDefault()
    const desktopImageUrl = event.currentTarget.dataset.lightboxSrc
    const mobileImageUrl = event.currentTarget.dataset.lightboxMobileSrc

    // Determine which image URL to use based on screen width
    const imageUrl = window.innerWidth >= 768 ? desktopImageUrl : mobileImageUrl

    // Set the image URL in the modal
    this.imageTarget.src = imageUrl

    // Show the modal
    this.modalTarget.classList.remove('hidden')
  }

  close(event) {
    if (event.target === this.modalTarget || event.currentTarget === event.target) {
      // Hide the modal
      this.modalTarget.classList.add('hidden')
    }
  }
}