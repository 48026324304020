import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['fee', 'input'];

  connect() {
    this.toggleInput(false);
  }

  toggleInput(show) {
    if (this.hasFeeTarget) {
      this.feeTarget.classList.toggle('hidden', show);
    }
    if (this.hasInputTarget) {
      this.inputTarget.classList.toggle('hidden', !show);
    }
  }

  editFee() {
    this.toggleInput(true);
    this.inputTarget.focus();
  }

  async updateFee() {
    const existingFee = parseFloat(this.feeTarget.innerHTML.replace('$', '').replace(',', ''));
    const newFee = this.inputTarget.value;

    const request = new FetchRequest('PATCH', '/update_pallet_fee', { body: { pallet_fee: newFee }, responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      this.feeTarget.innerHTML = `$${parseFloat(newFee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      this.updateOrderTotal(existingFee, newFee)
    } else {
      const responseJson = await response.json
      alert('Something went wrong. Errors: ' + responseJson.errors.join(', '))
    }

    this.toggleInput(false);
  }

  updateOrderTotal(existingFee, newFee) {
    let orderTotal = document.querySelector('[data-billing-target="SummaryTotalAmount"]');

    if (orderTotal) {
      const orderTotalValue = parseFloat(orderTotal.innerHTML.replace('$', '').replace(',', ''));
      const orderTotalValueNew = orderTotalValue - existingFee + parseFloat(newFee);
      orderTotal.innerHTML = `$${orderTotalValueNew.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
  }
}