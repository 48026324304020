import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "Input", "SuccessIndicator", "InvalidIndicator", "ExpiredCol1", "ExpiredCol2"
  ]

  connect() {
    setTimeout(() => this.handleValueOnLoad(), 100)
  }

  handleValueOnLoad() {
    if (this.InputTarget.value != "") {
      const event = document.createEvent("HTMLEvents")
      event.initEvent("input", false, true)
      this.InputTarget.dispatchEvent(event)
    }
  }

  async onCodeChange(e) {
    if (e.target.value == "") {
      this.SuccessIndicatorTarget.classList.add('hidden')
      this.InvalidIndicatorTarget.classList.add('hidden')
    } else {
      const request = new FetchRequest("GET", `${e.target.dataset.url}?code=${e.target.value}`, { responseKind: 'json' })
      const response = await request.perform()

      if (response.ok) {
        const responseJson = await response.json

        if (responseJson.success) {
          this.SuccessIndicatorTarget.classList.remove('hidden')
          this.InvalidIndicatorTarget.classList.add('hidden')
        } else {
          this.SuccessIndicatorTarget.classList.add('hidden')
          this.InvalidIndicatorTarget.classList.remove('hidden')
        }

        if (responseJson.expired) {
          this.ExpiredCol1Target.classList.remove('hidden')
          this.ExpiredCol2Target.classList.remove('hidden')
        } else {
          this.ExpiredCol1Target.classList.add('hidden')
          this.ExpiredCol2Target.classList.add('hidden')
        }
      }
    }
  }

}