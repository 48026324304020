import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { siteKey: String };

  connect() {
    this.element[this.identifier] = this;

    this.initializeRecaptcha();
  }

  initializeRecaptcha() {
    if (typeof window.grecaptcha !== 'undefined') {
      grecaptcha.ready(() => {
        const recaptchaContainer = document.getElementById('recaptcha-container');
        if (recaptchaContainer) {
          recaptchaContainer.innerHTML = '';
        }

        if (document.getElementById("recaptchaV2")) {
          grecaptcha.render("recaptchaV2", { sitekey: this.siteKeyValue });
        }
      });
    } else {
      setTimeout(() => this.initializeRecaptcha(), 100); // retry after 100ms
    }
  }

  resetRecaptcha() {
    if (window.grecaptcha && document.getElementById("recaptchaV2")) {
      grecaptcha.reset();
      this.initializeRecaptcha();
    }
  }
}
