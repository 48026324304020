// app/frontend/controllers/compare_mode_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["CompareModeButton", "CompareModeInstructions", "CancelComparisonsButton", "FloatingActionsContainer", "ViewComparisonsButton", "CompareButton", "CompareButton", "UncheckedCheckbox", "CheckedCheckbox"]

  hybridsController() {
    const element = document.querySelector("[data-controller~='hybrids']")
    return this.application.getControllerForElementAndIdentifier(element, 'hybrids')
  }

  connect() {
    this.selectingComparisonsMode = false
    this.selectedListings = []

    // Check URL for comparison mode on load
    const urlParams = new URLSearchParams(window.location.search);
    const compareIdsParam = urlParams.get("compare_ids");
    if (compareIdsParam) {
      this.selectedListings = compareIdsParam.split(",");
      if (this.hasListingCheckboxTargets) {
        this.ListingCheckboxTargets.forEach((checkbox) => {
          if (this.selectedListings.includes(checkbox.dataset.listingId)) {
          checkbox.checked = true;
          }
        });
        this.showFloatingActions();
      }
    }
  }

  onCompareModeButtonClick() {
    this.selectingComparisonsMode = true

    this.CancelComparisonsButtonTarget.classList.remove('hidden')
    this.CompareModeButtonTarget.classList.add('!hidden')
    this.CompareModeInstructionsTarget.classList.remove('hidden')
    this.hybridsController().hideAddToCartButtons()

    this.CompareButtonTargets.forEach(button => {
      button.classList.remove('hidden')
    })
    this.UncheckedCheckboxTargets.forEach(checkbox => {
      checkbox.style.display = 'block'
    })
    this.CheckedCheckboxTargets.forEach(checkbox => {
      checkbox.style.display = 'none'
    })
  }

  onCancelComparisonsButtonClick() {
    this.selectingComparisonsMode = false

    this.CancelComparisonsButtonTarget.classList.add('hidden')
    this.CompareModeButtonTarget.classList.remove('!hidden')
    this.CompareModeInstructionsTarget.classList.add('hidden')
    this.selectedListings = []

    this.hybridsController().showAddToCartButtons()
    this.CompareButtonTargets.forEach(button => {
      button.classList.add('hidden')
      button.classList.remove('bg-ux-blue-accent', 'text-white')
      button.classList.add('bg-white', 'text-ux-blue-accent')
    })
    this.UncheckedCheckboxTargets.forEach(checkbox => {
      checkbox.style.display = 'block'
    })
    this.CheckedCheckboxTargets.forEach(checkbox => {
      checkbox.style.display = 'none'
    })

    this.updateFloatingActionsContainerVisibility()

    // Remove compare_ids from URL
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("compare_ids");
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, "", newUrl);

    // Dispatch custom event
    const event = new CustomEvent("cancelCompare");
    document.dispatchEvent(event);
  }

  onCompareListingButtonClick(e) {
    e.preventDefault()
    e.stopPropagation()

    const listingId = e.currentTarget.dataset.listingId;

    if (this.selectedListings.includes(listingId)) {
      e.currentTarget.classList.remove('bg-ux-blue-accent', 'text-white')
      e.currentTarget.classList.add('bg-white', 'text-ux-blue-accent')

      e.currentTarget.querySelector('[data-compare-mode-target="UncheckedCheckbox"]').style.display = 'block'
      e.currentTarget.querySelector('[data-compare-mode-target="CheckedCheckbox"]').style.display = 'none'

      this.selectedListings = this.selectedListings.filter(id => id !== listingId)
    } else {
      e.currentTarget.classList.remove('bg-white', 'text-ux-blue-accent')
      e.currentTarget.classList.add('bg-ux-blue-accent', 'text-white')

      e.currentTarget.querySelector('[data-compare-mode-target="UncheckedCheckbox"]').style.display = 'none'
      e.currentTarget.querySelector('[data-compare-mode-target="CheckedCheckbox"]').style.display = 'block'

      this.selectedListings.push(listingId)
    }

    this.updateFloatingActionsContainerVisibility()
  }

  updateFloatingActionsContainerVisibility() {
    if (this.hasFloatingActionsContainerTarget) {
      if (this.selectedListings.length > 1) {
        this.FloatingActionsContainerTarget.classList.remove('hidden')
      } else {
        this.FloatingActionsContainerTarget.classList.add('hidden')
      }
    }
  }

  onViewComparisonsClick(e) {
    e.preventDefault();

    // Update the URL with compare_ids parameter
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("compare_ids", this.selectedListings.join(","));
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, "", newUrl);

    // Dispatch custom event
    const event = new CustomEvent("compare", {
      detail: { compareIds: this.selectedListings },
    });
    document.dispatchEvent(event);
  }

}
