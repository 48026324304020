import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["NotesTextArea"]

  connect() {
    if (this.hasNotesTextAreaTarget) {
      this.NotesTextAreaTarget.style.display = "block !important"
    }
    // console.log('this.NotesTextAreaTarget', this.NotesTextAreaTarget)
  }

}