import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = [
    "BgOverlay", "ModalContent", "ModalContainer",
    "CurrentCostInput", "H85CostInput", "PlantPopulationInput", "AcresPlantedInput",
    "TotalSavingsResult", "SavingsPerAcreResult"
  ]

  connect() {
    this.element[this.identifier] = this

    this.showPopupViaUrl()
  }

  showPopupViaUrl() {
    let urlHash = window.location.hash.replace("#", "").toLowerCase()

    if (urlHash && urlHash == "calculator") {
      const e = new Event('click')
      setTimeout(() => {
        this.toggleModal(e)
        this.element.scrollIntoView()
      }, 500)
    }
  }

  toggleModal(e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.ModalContainerTarget.classList.contains('hidden')) {
      this.ModalContainerTarget.classList.remove('hidden', 'pointer-events-none')
      document.querySelector('html').style.overflow = 'hidden'
      enter(this.BgOverlayTarget)
      enter(this.ModalContentTarget)
    } else {
      leave(this.BgOverlayTarget)
      leave(this.ModalContentTarget)
      document.querySelector('html').style.overflow = ''
      setTimeout(() => this.ModalContainerTarget.classList.add('hidden', 'pointer-events-none'), 300)
    }
  }

  onDataEntry() {
    const costPerBag = Number(this.CurrentCostInputTarget.value.replace("$", ""))
    const h85CostPerbag = Number(this.H85CostInputTarget.value.replace("$", ""))
    const plantPop = Number(this.PlantPopulationInputTarget.value || 30000)
    const acresPlanted = Number(this.AcresPlantedInputTarget.value || 100)

    if (isNaN(costPerBag) || costPerBag === 0 || isNaN(plantPop) || plantPop === 0 || isNaN(acresPlanted) || acresPlanted === 0) {
      this.TotalSavingsResultTarget.innerHTML = "&mdash;"
      this.SavingsPerAcreResultTarget.innerHTML = "&mdash;"
    } else {
      const bagsPerAcre = (plantPop / 80000)
      const costPerAcreTraditional = bagsPerAcre * costPerBag
      const costPerAcreOurs = bagsPerAcre * h85CostPerbag
      const savingsPerAcre = (costPerAcreTraditional - costPerAcreOurs)
      const totalCostTraditional = costPerAcreTraditional * acresPlanted
      const totalCostWithUs = costPerAcreOurs * acresPlanted
      const totalSavingsWithUs = (totalCostTraditional - totalCostWithUs)

      this.TotalSavingsResultTarget.innerHTML = Number(totalSavingsWithUs.toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })
      this.SavingsPerAcreResultTarget.innerHTML = Number(savingsPerAcre.toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })
    }
  }

}