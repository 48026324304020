import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static values = {
    timeout: Number,
    cookieSeenOnceKey: String,
    cookieSeenTwiceKey: String,
  }

  get modalController() {
    const element = document.querySelector(`[data-controller="modal-hotwired"]`);
    return element ? element[`modal-hotwired`] : null;
  }
  get recaptchaV2Controller() {
    const element = document.querySelector(`[data-controller="recaptcha-v2"]`);
    return element ? element[`recaptcha-v2`] : null;
  }
  get recaptchaV3Controller() {
    const element = document.querySelector(`[data-controller="recaptcha-v3"]`);
    return element ? element[`recaptcha-v3`] : null;
  }

  connect() {
    this.element[this.identifier] = this;
    this.DELAY = this.timeoutValue;
    this.timeout = null;

    this.startShowDelay();
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  startShowDelay() {
    this.timeout = setTimeout(() => {
      this.displayModal();

      if (Cookies.get(this.cookieSeenOnceKeyValue) === undefined) {
        Cookies.set(this.cookieSeenOnceKeyValue, true);
      } else {
        Cookies.set(this.cookieSeenTwiceKeyValue, true);
      }
    }, this.DELAY);
  }

  displayModal() {
    if (this.modalController) {
      this.modalController.open();
    }

    if (this.recaptchaV3Controller) {
      this.recaptchaV3Controller.executeRecaptcha();
    }

    if (this.recaptchaV2Controller) {
      this.recaptchaV2Controller.resetRecaptcha();
    }
  }
}
