import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ExpandButton", "CollapseButton", "Answer"]
  static values = {
    isOpen: Boolean,
  }

  connect() {
    this.element[this.identifier] = this
  }

  onExpand(e) {
    e.preventDefault()

    if (!this.isOpenValue) {
      this.ExpandButtonTarget.classList.add("hidden")
      this.CollapseButtonTarget.classList.remove("hidden")
      this.AnswerTarget.classList.remove("hidden")
      this.AnswerTarget.classList.add("mt-2", "!h-auto", "!opacity-100", "pt-4")
    } else {
      this.ExpandButtonTarget.classList.remove("hidden")
      this.CollapseButtonTarget.classList.add("hidden")
      this.AnswerTarget.classList.add("hidden")
      this.AnswerTarget.classList.remove("mt-2", "!h-auto", "!opacity-100", "pt-4")
    }

    this.isOpenValue = !this.isOpenValue
  }
}