import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["PromoCodeInput", "SuccessIndicator", "InvalidIndicator"]

  connect() {
    this.element[this.identifier] = this
  }

  async onCodeChange(e) {
    const code = e.currentTarget.value

    if (code == "") {
      this.SuccessIndicatorTarget.classList.add('hidden')
      this.InvalidIndicatorTarget.classList.add('hidden')
    } else {
      const request = new FetchRequest("GET", `${e.target.dataset.url}?code=${e.target.value}`, { responseKind: 'json' })
      const response = await request.perform()

      if (response.ok) {
        const responseJson = await response.json

        if (responseJson.success) {
          this.SuccessIndicatorTarget.classList.add('hidden')
          this.InvalidIndicatorTarget.classList.remove('hidden')
        } else {
          this.SuccessIndicatorTarget.classList.remove('hidden')
          this.InvalidIndicatorTarget.classList.add('hidden')
        }
      }
    }
  }

}