import { Controller } from "@hotwired/stimulus"
import { debounce } from "~/lib/debounce"

export default class extends Controller {

  // targets required: item-container
  // targets auto-added: item
  static targets = ["itemContainer", "item"]
  static values  = {
    resetToStartDelay: Number,
    slideDelay: Number,
  }

  connect() {
    this.mouseover = false
    this.slideDelayValue = this.slideDelayValue || 10000
    this.slideInterval = null
    this.uuid = Math.random().toString(36).substr(2, 9);
    if (!this.hasResetToStartDelayValue) this.resetToStartDelayValue = 500

    this.initContainer()
    this.initItems()
    this.maxSlideIndex = this.itemTargets.length

    this.startSliding()
  }

  initContainer() {
    this.itemContainerTarget.classList.add("flex", "overflow-x-auto")
    this.itemContainerTarget.style.cssText = "-webkit-overflow-scrolling: touch; scroll-snap-type: x mandatory; scroll-behavior: smooth;"
    this.itemContainerTarget.id = `slider-${this.uuid}-item-container`

    this.itemContainerTarget.addEventListener("scroll", debounce(this.onSlideUserScroll.bind(this), 100));
    this.itemContainerTarget.addEventListener("touchmove", debounce(this.onSlideUserScroll.bind(this), 100));
  }

  initItems() {
    Array.prototype.slice.call(this.itemContainerTarget.children).map((item, index) => {
      item.dataset.sliderTarget = "item"
      item.classList.add("flex-shrink-0")
      item.style.cssText += "scroll-snap-align: start;"
      item.id = `slider-${this.uuid}-slide-${index}`
    })
  }

  onMouseOver(e) {
    this.mouseover = true
  }

  onMouseLeave(e) {
    this.mouseover = false
  }

  onSlideUserScroll(e) {
    if(this.mouseover && this.slideInterval) {
      clearInterval(this.slideInterval)
    }
  }

  onLeftArrowClick(e) {
    e.preventDefault()

    this.onEitherArrowClick()
    var newSlideIndex = this.currentSlideIndex() - 1
    if (newSlideIndex < 0) {
      newSlideIndex = this.maxSlideIndex
    }

    this.scrollToSlideIndex(newSlideIndex)
  }

  onRightArrowClick(e) {
    e.preventDefault()

    this.onEitherArrowClick()
    var newSlideIndex = this.currentSlideIndex() + 1
    if (newSlideIndex == this.maxSlideIndex) {
      newSlideIndex = 0
    }

    this.scrollToSlideIndex(newSlideIndex)
  }

  onEitherArrowClick() {
    clearInterval(this.slideInterval)
  }

  // starts at 0
  currentSlideIndex() {
    var slideItem = this.itemTargets[0]
    var slideWidth = slideItem.offsetWidth
    var currentContainerLeft = this.itemContainerTarget.scrollLeft
    return Math.round(currentContainerLeft / slideWidth)
  }

  scrollToSlideIndex(index) {
    var slideItem = this.itemTargets[0]
    var slideWidth = slideItem.offsetWidth
    var scrollToLeftVal = slideWidth * index

    this.itemContainerTarget.scrollLeft = scrollToLeftVal
  }

  startSliding() {
    // reset to first slide after content loaded
    setTimeout(() => this.scrollToSlideIndex(0), this.resetToStartDelayValue)

    this.slideInterval = setInterval(() => {
      var nextIndex = this.currentSlideIndex() + 1
      if (nextIndex >= this.maxSlideIndex) {
        nextIndex = 0
      }

      this.scrollToSlideIndex(nextIndex)
    }, this.slideDelayValue)
  }

}
