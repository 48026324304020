import { Controller } from "@hotwired/stimulus"
import { debounce } from "~/lib/debounce"

export default class extends Controller {

  static targets = ["tableContainer", "lastMobileColumn", "lastNonMobileColumn"]

  initialize() {
    this.element.classList.add("relative")
    this.isMobile = false

    this.tableContainerTarget.addEventListener("scroll", debounce(this.onTableScroll.bind(this), 100))
    this.tableContainerTarget.addEventListener("touchmove", debounce(this.onTableScroll.bind(this)))

    setTimeout(this.onTableScroll.bind(this), 6500)

    this.blurredEdge = document.createElement("div")
    this.blurredEdge.style.cssText = `display: inline-block; position: relative; top: 0px; left: ${this.isMobile ? '-18px' : '-35px'}; width: ${this.isMobile ? '18px' : '35px'}; height: 100%; background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(229, 231, 235, 1) 100%);`

    this.scrollVisualElement = document.createElement("div")
    this.scrollVisualElement.classList.add("absolute", "top-0", "right-0", "h-full", "w-8", "sm:w-16", "bg-gray-200", "opacity-80")
    this.scrollVisualElement.append(this.blurredEdge)

    this.scrollArrowElement = document.createElement("div")
    this.scrollArrowElement.id = "scroll-arrow-element"
    this.scrollArrowElement.style.position = "absolute"
    this.scrollArrowElement.style.top = '0px'
    this.scrollArrowElement.classList.add("right-1", "opacity-50", "w-8", "sm:w-16", "h-8", "sm:h-16")

    this.rightArrowElement = document.createElement("div")
    this.rightArrowElement.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>'.trim()

    // handle element starting hidden and showing
    this.respondToVisibility(this.element, (visible) => {
      if (visible) {
        this.appendScrollVisualElement()
      }
    })

    this.appendScrollVisualElement()
  }

  respondToVisibility(element, callback) {
    var options = {
      root: document.documentElement,
    };

    var observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        callback(entry.intersectionRatio > 0);
      });
    }, options);

    observer.observe(element);
  }

  onTableScroll() {
    var mobileTable = this.element.getElementsByTagName("table")[0]
    this.isMobile = getComputedStyle(mobileTable).display != "none"

    this.showHideScrollIndicator()
    this.positionArrowVertically()
  }

  showHideScrollIndicator() {
    if (this.lastColumnOnScreen()) {
      this.scrollVisualElement.style.display = 'none'
      this.blurredEdge.style.display = 'none'
    } else {
      this.scrollVisualElement.style.display = 'block'
      this.blurredEdge.style.display = 'inline-block'
    }
  }

  positionArrowVertically() {
    if (this.scrollArrowElement) {
      var scrollTop = window.scrollTop,
        scrollBot = scrollTop + window.offsetHeight,
        elTop = this.tableContainerTarget.offsetTop,
        elBottom = elTop + this.tableContainerTarget.offsetHeight,
        visibleTop = elTop < scrollTop ? scrollTop : elTop,
        visibleBottom = elBottom > scrollBot ? scrollBot : elBottom,
        visibleHeight = visibleBottom - visibleTop
      var scrolledOffTop = 0
      var arrowHeight = this.scrollArrowElement.getBoundingClientRect().height
      var thOffset
      if (this.isMobile) {
        thOffset = 0
      } else {
        thOffset = 24
      }

      if (visibleTop == 0 && elTop <= 0) {
        scrolledOffTop = visibleTop - elTop
      } else {
        scrolledOffTop = 0
      }

      var newArrowTop = Math.round((visibleHeight / 2) - (arrowHeight / 2) + scrolledOffTop + thOffset)
      this.scrollArrowElement.style.top = `${newArrowTop}px`
    }
  }

  appendScrollVisualElement() {
    if (this.tableContainerTarget.scrollWidth > this.tableContainerTarget.clientWidth) {
      this.element.append(this.scrollVisualElement)
      this.addScrollArrowElement()
    }
  }

  addScrollArrowElement() {
    this.scrollArrowElement.append(this.rightArrowElement)
    this.scrollVisualElement.append(this.scrollArrowElement)
  }

  lastColumnOnScreen() {
    var col = null

    if (this.isMobile) {
      col = this.lastMobileColumnTarget
    } else {
      col = this.lastNonMobileColumnTarget
    }
    var rect = col.getBoundingClientRect()
    return rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  }

}