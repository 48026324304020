import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    siteKey: String,
    action: String,
    mode: String
   };
  static targets = ["Input"];

  connect() {
    this.element[this.identifier] = this;

    if (this.modeValue == "automatic") {
      this.executeRecaptcha();
    }
  }

  executeRecaptcha() {
    const currentTime = new Date().getTime();

    // Retrieve the last token time from the dataset
    const lastTokenTime = this.InputTarget.dataset.lastTokenTime
                         ? parseInt(this.InputTarget.dataset.lastTokenTime)
                         : null;

    // Check if a token has been fetched and if it's less than 2 minutes old
    if (lastTokenTime && (currentTime - lastTokenTime < 120000)) {
      console.log('Using existing token as it is less than 2 minutes old');
      return;
    }

    if (typeof window.grecaptcha !== 'undefined') {
      grecaptcha.ready(() => {
        grecaptcha.execute(this.siteKeyValue, { action: this.actionValue }).then((token) => {
          this.InputTarget.value = token;
          // Store the current time as the token fetch time in the dataset
          this.InputTarget.dataset.lastTokenTime = currentTime.toString();
        });
      });
    } else {
      setTimeout(() => this.executeRecaptcha(), 100); // retry after 100ms
    }
  }
}
