import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["ShippingForm", "ShippingPrice", "DiscountCodeVisibleInput", "DiscountCodeHiddenInput", "DestinationZipcodeInput", "SubmitButton"]

  connect() {
    if (Cookies.get('delivery_method') == "pickup") {
      this.ShippingFormTarget.classList.add('hidden')
      this.DiscountCodeVisibleInputTarget.value = this.DiscountCodeVisibleInputTarget.value.replace(/_?LCLPU_?/g, "")
      this.DestinationZipcodeInputTarget.value = "68025"
    } else {
      this.DiscountCodeHiddenInputTarget.value = this.DiscountCodeVisibleInputTarget.value.replace(/_?LCLPU_?/g, "")
      this.DiscountCodeVisibleInputTarget.value = this.DiscountCodeVisibleInputTarget.value.replace(/_?LCLPU_?/g, "")
    }
    this.initCustomerZipcode()

    this.DiscountCodeVisibleInputTarget.addEventListener("input", this.onVisibleCodeChange.bind(this))

    this.onDeliveryMethodChange({ currentTarget: { value: Cookies.get('delivery_method') } })

  }

  initCustomerZipcode() {
    if (Cookies.get('admin_selected_customer')) {
      const customer = JSON.parse(Cookies.get('admin_selected_customer'))
      console.log('customer', customer)

      if (this.hasDestinationZipcodeInputTarget) {
        setTimeout(() => {
          this.DestinationZipcodeInputTarget.value = customer.most_recent_order.shipping_zipcode
          this.DestinationZipcodeInputTarget.dispatchEvent(new Event('keyup'))
        }, 1000)
      }
    }
  }

  onDeliveryMethodChange(e) {
    Cookies.set('delivery_method', e.currentTarget.value)
    const existingUserDiscountValue = this.DiscountCodeVisibleInputTarget.value

    if (e.currentTarget.value == "pickup") {
      this.ShippingFormTarget.classList.add('hidden')
      this.ShippingPriceTarget.innerHTML = "$0"
      this.DiscountCodeHiddenInputTarget.value = `${existingUserDiscountValue}${existingUserDiscountValue != "" ? "_LCLPU" : "LCLPU"}`
      this.DestinationZipcodeInputTarget.value = "68025"
      this.SubmitButtonTarget.innerHTML = "Continue to Payment"
    } else {
      this.ShippingFormTarget.classList.remove('hidden')
      this.ShippingPriceTarget.innerHTML = "Calculated in Next Step"
      this.DiscountCodeHiddenInputTarget.value = `${existingUserDiscountValue}`
      const cookieZipcode = Cookies.get('destination_zipcode')
      if (cookieZipcode) {
        this.DestinationZipcodeInputTarget.value = cookieZipcode
      }
      this.SubmitButtonTarget.innerHTML = "Find Shipping Price"
    }
  }

  onZipcodeChange(e) {
    Cookies.set('destination_zipcode', e.currentTarget.value)
  }

  // make this keep an existing LCLPU hidden value while appending the new value
  onVisibleCodeChange(e) {
    const existingHiddenValue = this.DiscountCodeHiddenInputTarget.value
    const hasLocalPickup = existingHiddenValue.includes("LCLPU")

    let newHiddenValue = e.currentTarget.value
    if (hasLocalPickup && newHiddenValue != "") {
      // preserve any existing LCLPU hidden value
      newHiddenValue = newHiddenValue + "_LCLPU"
    }
    if (newHiddenValue == "" && Cookies.get('delivery_method') == "pickup") {
      newHiddenValue = "LCLPU"
    }

    this.DiscountCodeHiddenInputTarget.value = newHiddenValue
  }
}