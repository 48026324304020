import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "splitInput", "result", "fullCost" ]
  static values = {
    fullCost: Number,
    numberOfUnits: Number,
  }

  toggle(e) {
    e.preventDefault()
    e.stopPropagation()

    this.formTarget.classList.toggle("hidden");
  }

  calculate(e) {
    e.preventDefault()
    e.stopPropagation()

    const splitValue = this.splitInputTarget.value;

    const splitPercentage = (splitValue / this.numberOfUnitsValue)
    const splitCost = (splitPercentage * this.fullCostValue).toFixed(2)

    this.resultTarget.textContent = `Split Cost: $${splitCost}`;
  }

  updateSelectedRate(rate) {
    this.fullCostValue = rate
    this.fullCostTarget.innerHTML = `$${rate}`

    this.calculate(new Event('click'))
  }
}