import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["wrapper", "ContentContainer", "body"]
  static values = {
    closed: { type: Boolean, default: true },
    reusable: { type: Boolean, default: true },
    skipSubmitClose: Boolean
  }

  connect() {
    this.element[this.identifier] = this

    if (!this.closedValue) {
      this.open()
    }

    if (!this.skipSubmitCloseValue) {
      document.addEventListener('turbo:submit-end', this.handleSubmit)
    }
  }

  disconnect() {
    if (!this.skipSubmitCloseValue) {
      document.removeEventListener('turbo:submit-end', this.handleSubmit)
    }
  }

  open() {
    enter(this.wrapperTarget)
    enter(this.bodyTarget)
    this.ContentContainerTarget.classList.remove('pointer-events-none')

    // Disable scroll on body
    const scrollY = window.scrollY
    document.body.style.position = "fixed"
    document.body.style.top = `-${scrollY}px`
  }

  close(e) {
    if (e) { e.preventDefault() }

    leave(this.wrapperTarget)
    leave(this.bodyTarget).then(() => {
      if (!this.reusableValue) {
        // Remove the modal element after the fade out so it doesn't blanket the screen
        this.element.remove()
      }

      this.ContentContainerTarget.classList.add('pointer-events-none')
    })


    if (!this.reusableValue) {
      // Remove src reference from parent frame element
      // Without this, turbo won't re-open the modal on subsequent clicks
      this.element.closest("turbo-frame").src = undefined
    }

    // enable scroll on body & restore scroll position
    const scrollY = document.body.style.top
    document.body.style.position = ""
    document.body.style.top = ""
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }
}
