// app/frontend/controllers/map_controller.js
import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from '@rails/request.js'
import L from '../js/leaflet';
import 'leaflet/dist/leaflet.css';

export default class extends Controller {
  static values = {
    zipcodeBoundaryUrl: String,
  }

  connect() {
    this.initMap();
    this.selectedZipcode = null;
    this.mapReady = false;

    this.initializeFromUrlParams();
  }

  disconnect() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  }

  async initMap() {
    if (this.map) {
      this.map.remove()
      this.element.innerHTML = ""
      this.map = null
    }

    this.mapReadyPromise = new Promise((resolve) => {
      if (L) {
        this.initMapComponents()
        resolve()
      } else {
        setTimeout(() => {
          this.initMapComponents()
          resolve()
        }, 100)
      }
    })

    return this.mapReadyPromise
  }


  initMapComponents() {
    try {
      this.countyLayers = []
      this.countyGroup = L.layerGroup();
      this.map = L.map(this.element, {
        attributionControl: false,
        scrollWheelZoom: false,
      }).setView([40.7922, -96.70253], 5);

      this.setMapTileLayer()

      this.drawCountyBoundaries()
      this.drawStateBoundaries()
      this.countyGroup.addTo(this.map)
    } catch (error) {
      console.error('Error initializing the map:', error);
    }
  }

  setMapTileLayer() {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap contributors'
    }).addTo(this.map);

    // window.L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
    //   attribution: '© OpenStreetMap contributors, © CARTO'
    // }).addTo(this.map);
  }

  countyBorderStyle() {
    return {
      color: '#c3cdd5', // Border color
      // color: 'transparent',
      weight: 1,       // Border width
      fillOpacity: 0,   // No fill
      fillColor: null
    };
  }

  drawCountyBoundaries() {
    fetch('/counties.geojson')
      .then(response => response.json())
      .then(data => {
        // add geoJSON layer to the map
        L.geoJson(data, {
          style: this.countyBorderStyle.bind(this),
          onEachFeature: (_, layer) => {
            this.countyLayers.push(layer)
          }
        }).addTo(this.map);
      })
      .catch(error => console.error('Error loading the GeoJSON file:', error))
  }

  drawStateBoundaries() {
    fetch('/states.geojson') // Ensure you have the correct path to your states GeoJSON file
      .then(response => response.json())
      .then(data => {
          L.geoJson(data, {
              style: () => ({
                  color: '#ababab', // Darker border color for states
                  weight: 2,        // Thicker border width for states
                  fillOpacity: 0,   // Typically, state boundaries wouldn't be filled
              })
          }).addTo(this.map);
      })
      .catch(error => console.error('Error loading the states GeoJSON file:', error));
  }

  async fetchAndDrawZipcodeBoundary() {
    const url = this.element.dataset.zipcodeBoundaryUrl;

    const request = new FetchRequest('POST', url, {
      body: JSON.stringify({ zipcode: this.selectedZipcode }),
      responseKind: 'json',
    });
    const response = await request.perform();

    if (response.ok) {
      const responseJson = await response.json;

      this.drawCountyBoundingBox(responseJson);
    } else {
      const responseJson = await response.json;
      if (responseJson.error == 'Invalid Zipcode') {
        this.ZipcodeErrorTarget.classList.remove('hidden');
      } else {
        alert(responseJson.error);
      }
      // Remove existing bounding box if any
      if (this.boundingBoxLayer) {
        this.map.removeLayer(this.boundingBoxLayer);
      }
    }
  }

  drawCountyBoundingBox(responseJson) {
    if (this.map) {
      this.map.setView([responseJson.lat, responseJson.lon], 7);

      // Draw bounding box
      const boundingBox = responseJson.bounding_box.map(coord => parseFloat(coord));
      const southWest = L.latLng(boundingBox[0], boundingBox[2]);
      const northEast = L.latLng(boundingBox[1], boundingBox[3]);
      const bounds = L.latLngBounds(southWest, northEast);

      // Remove existing bounding box if any
      if (this.boundingBoxLayer) {
        this.map.removeLayer(this.boundingBoxLayer);
      }

      // Add new bounding box layer
      this.boundingBoxLayer = L.rectangle(bounds, {color: "#ff7800", weight: 1}).addTo(this.map);
    }
  }

  async onZipcodeChange(zipcode) {
    this.selectedZipcode = zipcode;
    this.fetchAndDrawZipcodeBoundary();
  }

  async initializeFromUrlParams() {
    const params = new URLSearchParams(window.location.search);

    // Set zipcode
    if (params.has('zipcode')) {
      this.selectedZipcode = params.get('zipcode');

      if (this.selectedZipcode) {
        // Ensure the map is ready
        await this.mapReadyPromise;
        // Now we can draw the zipcode boundary
        await this.fetchAndDrawZipcodeBoundary();
      }
    }
  }

}