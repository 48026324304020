import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    mapReady: Boolean,
    stateSelection: String,
    yearSelection: String,
  }

  isProgrammaticZoom = false
  isProgrammaticBack = false

  filterTableController() {
    var div = document.getElementById("yield-data-filter-table")
    return this.application.getControllerForElementAndIdentifier(div, "filter-table")
  }

  initialize() {
    this.locationData = JSON.parse(this.data.get("locationData"))
    this.latLongKeys = {}
    this.isProgrammaticZoom = false
    this.isProgrammaticBack = false
  }

  mapReadyValueChanged() {
    if (this.mapReadyValue == true) {
      // Map is loaded & ready
      // Initialize map with current filters
      this.updateMap()
      simplemaps_usmap.hooks.zoomable_click_state = this.onStateClick.bind(this)
      simplemaps_usmap.hooks.back = this.onUsMapsZoomBack.bind(this)
      setTimeout(function() {
        this.handleQueryParam()
      }.bind(this), 750)
    }
  }

  // Modify colorStatesWithValues to accept filtered data
  colorStatesWithValues(data) {
    // Reset all states to default color
    Object.keys(simplemaps_usmap_mapdata.state_specific).forEach((key) => {
      simplemaps_usmap_mapdata.state_specific[key].color = "#d1d5db" // Default gray
      simplemaps_usmap_mapdata.state_specific[key].hover_color = "#d1d5db"
      simplemaps_usmap_mapdata.state_specific[key].description = ""
    })

    var statesAndTotals = {}
    data.map((item) => {
      statesAndTotals[item.state] ||= 0
      statesAndTotals[item.state] += 1
    })

    Object.keys(statesAndTotals).map((key) => {
      simplemaps_usmap_mapdata.state_specific[key].color = "#c1a01e" // Yellow
      simplemaps_usmap_mapdata.state_specific[key].hover_color = "#d0af21" // Hover color
      simplemaps_usmap_mapdata.state_specific[key].description = `${statesAndTotals[key]} Result${statesAndTotals[key] == 1 ? '' : 's'}`
    })
  }

  latLongKeyLookup(key) {
    if (this.latLongKeys[key]) {
      return this.latLongKeys[key]
    }

    this.locationData.map((item) => {
      this.latLongKeys[item.latitude+item.longitude] ||= 0
      this.latLongKeys[item.latitude + item.longitude] += 1
    })

    return this.latLongKeys[key]
  }

  updateYearGrownSelected(year) {
    this.yearSelectionValue = year
    this.updateMap()
  }

  updateStateSelected(state) {
    this.stateSelectionValue = state
    this.updateMap()

    if (window.simplemaps_usmap) {
      if (simplemaps_usmap.back) {
        if (this.stateSelectionValue == "") {
          this.isProgrammaticBack = true
          setTimeout(() => {
            simplemaps_usmap.back()
          }, 500)
        } else {
          this.isProgrammaticZoom = true
          simplemaps_usmap.state_zoom(this.stateSelectionValue)
        }
      }
    }
  }

  onStateClick(state) {
    if (this.isProgrammaticZoom) {
      // Reset the flag and return to prevent loop
      this.isProgrammaticZoom = false
      return
    }
    if (this.filterTableController()) {
      this.filterTableController().updateStateSelected(state)
    }
    this.stateSelectionValue = state
  }

  onUsMapsZoomBack() {
    if (this.isProgrammaticBack) {
      // Reset the flag and return to prevent loop
      this.isProgrammaticBack = false
      return
    }
    if (this.filterTableController()) {
      this.filterTableController().updateStateSelected("")
    }
    this.stateSelectionValue = ""
  }

  handleQueryParam() {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    var yieldStateParam = undefined
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == "yield_state") {
        yieldStateParam = pair[1]
      }
    }

    if (yieldStateParam !== undefined) {
      this.onStateClick(yieldStateParam)
      // Scroll to yield data section
      var elem = document.querySelector("#yield-data")
      var yOffset = -100
      var y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  // Update the map with the filtered data
  updateMap() {
    // Filter locationData based on state and year selections
    let filteredLocationData = this.locationData.filter((item) => {
      let stateMatch = this.stateSelectionValue === '' || item.state === this.stateSelectionValue
      let yearMatch = this.yearSelectionValue === '' || item.year_grown.toString() === this.yearSelectionValue
      return stateMatch && yearMatch
    })

    // Clear existing locations
    simplemaps_usmap_mapdata.locations = []

    // Update latLongKeys
    this.latLongKeys = {}
    filteredLocationData.forEach((item) => {
      let key = item.latitude + item.longitude
      this.latLongKeys[key] = (this.latLongKeys[key] || 0) + 1
    })

    // Set location values
    filteredLocationData.forEach((item, index) => {
      let isDuplicateLocation = this.latLongKeys[item.latitude + item.longitude] > 1
      let randomizedLat = isDuplicateLocation
        ? parseFloat(item.latitude) + ((Math.random() > 0.5 ? 0.01 : -0.09) + Math.random() * 0.33)
        : item.latitude
      let randomizedLng = isDuplicateLocation
        ? parseFloat(item.longitude) + ((Math.random() > 0.5 ? 0.01 : -0.09) + Math.random() * 0.33)
        : item.longitude

      simplemaps_usmap_mapdata.locations.push({
        lat: randomizedLat,
        lng: randomizedLng,
        name: `${item.year_grown} - ${item.data_type == "plot_trial" ? "Plot Trial" : "Farmer Grower"}`,
        color: item.data_type == "plot_trial" ? "#e91e63" : "#e91e63",
        size: 25,
        type: "marker",
        description: `<strong>${item.product} - ${item.relative_maturity} Day</strong><br/><strong>${item.yield} ${item.usage == "S" ? "ton/acre" : "bu/acre"} - ${item.usage == "S" ? "Silage" : "Grain"}</strong>${item.yield_notes ? "<br/>" + item.yield_notes : ""}${item.grower_notes ? "<br/>" + item.grower_notes : ""}${item.test_weight ? "<br>Test Weight: " + item.test_weight : ""}${item.percent_moisture ? "<br>Percent Moisture: " + item.percent_moisture : ""}`,
      })
    })

    // Update the state colors based on filtered data
    this.colorStatesWithValues(filteredLocationData)

    // Refresh the map
    if (window.simplemaps_usmap && window.simplemaps_usmap.refresh) {
      window.simplemaps_usmap.refresh()
    }
  }

}
