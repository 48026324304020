import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const seedSubtotal = parseFloat(this.element.dataset.orderSeedSubtotal)
    const email = this.element.dataset.orderEmail
    const phoneNumber = this.element.dataset.orderPhoneNumber
    const streetAddress = this.element.dataset.orderStreetAddress
    const firstName = this.element.dataset.firstName
    const lastName = this.element.dataset.lastName
    const city = this.element.dataset.orderCity
    const state = this.element.dataset.orderState
    const zipcode = this.element.dataset.orderZipcode
    const countryCode = this.element.dataset.countryCode
    const orderId = this.element.dataset.orderId

    // Set the enhanced conversions data as a global variable
    window.enhancedConversionsData = {
      'sha256_email_address': email,
      'sha256_phone_number': phoneNumber,
      'address': {
        'sha256_first_name': firstName,
        'sha256_last_name': lastName,
        'street': streetAddress,
        'city': city,
        'region': state,
        'postal_code': zipcode,
        'country': countryCode
      }
    };

    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'SubmitOrder',
        'conversionValue': seedSubtotal,
        'transactionId': orderId,
        'conversionEventId': orderId
      })
    }
  }

}