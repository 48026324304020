import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["CardFinancing", "CardDiscount", "CardTreatment", "RadioFinancing", "RadioDiscount", "RadioTreatment", "SelectionForm"]

  get cartController() {
    return this.application.controllers.find((controller) => controller.identifier == 'cart')
  }

  onCardSelect(e) {
    const value = e.currentTarget.dataset.value

    switch (value) {
      case 'financing':
        this.RadioFinancingTarget.checked = true
        this.RadioFinancingTarget.dispatchEvent(new Event('change'))
        break
      case 'discount':
        this.RadioDiscountTarget.checked = true
        this.RadioDiscountTarget.dispatchEvent(new Event('change'))
        break
      case 'treatment':
        this.RadioTreatmentTarget.checked = true
        this.RadioTreatmentTarget.dispatchEvent(new Event('change'))
        break
    }
  }

  onRadioSelect(e) {
    const value = e.currentTarget.value
    const selectedClasses = ['border-blue-600', 'ring-2', 'ring-blue-600']

    switch (value) {
      case 'financing':
        this.CardFinancingTarget.classList.add(...selectedClasses)
        this.CardDiscountTarget.classList.remove(...selectedClasses)
        if (this.hasCardTreatmentTarget) {
          this.CardTreatmentTarget.classList.remove(...selectedClasses)
        }
        break
      case 'discount':
        this.CardFinancingTarget.classList.remove(...selectedClasses)
        this.CardDiscountTarget.classList.add(...selectedClasses)
        if (this.hasCardTreatmentTarget) {
          this.CardTreatmentTarget.classList.remove(...selectedClasses)
        }
        break
      case 'treatment':
        this.CardFinancingTarget.classList.remove(...selectedClasses)
        this.CardDiscountTarget.classList.remove(...selectedClasses)
        if (this.hasCardTreatmentTarget) {
          this.CardTreatmentTarget.classList.add(...selectedClasses)
        }
        break
    }

    this.cartController.onPromoSelect(value)

    this.SelectionFormTarget.requestSubmit()
  }
}