import "@hotwired/turbo-rails";
import "~/controllers";
import * as Sentry from "@sentry/browser";

import "../stylesheets/index.sass";

document.addEventListener("turbo:load", (event) => {
  const target = event.target;
  executeInlineScript(target);
});

// Sentry
const sentryDsnMetaTag = document.querySelector("meta[property='sentry-dsn']");
if (sentryDsnMetaTag) {
  Sentry.init({
    dsn: sentryDsnMetaTag.content,
    maxBreadcrumbs: 50,
    // integrations: [
    //   Sentry.replayIntegration({
    //     maskAllText: false,
    //     blockAllMedia: true,
    //   }),
    // ],
    // // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// automatically execute script tags added by turbo_stream responses
function executeInlineScript(element) {
  const scripts = element.querySelectorAll("script");
  if (scripts.length > 0) {
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      newScript.innerHTML = script.innerHTML;
      element.appendChild(newScript);
    });
  }
}

// OpenReplay
// if (import.meta.env.VITE_OPEN_REPLAY_PROJECT_KEY) {
//   const tracker = new Tracker({
//     projectKey: import.meta.env.VITE_OPEN_REPLAY_PROJECT_KEY,
//     ingestPoint: import.meta.env.VITE_OPEN_REPLAY_URL,
//     onStart: ({ sessionToken, sessionID }) => {
//       // This is to link from OpenReplay -> Sentry
//       Sentry.setTag("openReplaySessionToken", sessionToken);

//       // This is to link from Sentry -> OpenReplay (requires tracker v3.6.0+)
//       Sentry.setTag("openReplaySessionURL", tracker.getSessionURL())
//     },
//   })
//   tracker.start()
// }