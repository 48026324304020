import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = [
    "BgOverlay", "ModalContent", "ModalContainer",
  ]

  hybridsController() {
    const element = document.querySelector("[data-controller~='hybrids']")
    return this.application.getControllerForElementAndIdentifier(element, 'hybrids')
  }

  connect() {
    this.boundHandleKeyup = this.handleKeyup.bind(this)
  }

  toggleModal(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (this.ModalContainerTarget.classList.contains('hidden')) {
      this.openModal()
    } else {
      this.closeModal()
    }
  }

  openModal() {
    this.ModalContainerTarget.classList.remove('hidden', 'pointer-events-none')
    document.querySelector('html').style.overflow = 'hidden'
    enter(this.BgOverlayTarget)
    enter(this.ModalContentTarget)

    document.addEventListener("keyup", this.boundHandleKeyup)
  }

  closeModal() {
    leave(this.BgOverlayTarget)
    leave(this.ModalContentTarget)
    document.querySelector('html').style.overflow = ''
    setTimeout(() => this.ModalContainerTarget.classList.add('hidden', 'pointer-events-none'), 300)

    document.removeEventListener("keyup", this.boundHandleKeyup)
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.closeModal()

      if (this.hybridsController()) {
        this.hybridsController().onCloseModal()
      }
    }
  }

  stopPropagation(e) {
    e.stopPropagation()
  }
}