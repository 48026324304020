import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "scrollContainer", "body"]
  static values = { listingsCount: Number }

  hybridsController() {
    const element = document.querySelector("[data-controller~='hybrids']")
    return this.application.getControllerForElementAndIdentifier(element, 'hybrids')
  }

  connect() {
    this.updateHeaderOffset()
    this.setGridTemplateColumns()
    setTimeout(() => {
      this.updateTableBodyPadding()
    }, 200)

    window.addEventListener('navbar-height-changed', this.handleLayoutChange.bind(this))
    window.addEventListener('resize', this.handleLayoutChange.bind(this))
    window.addEventListener('popstate', this.handlePopState.bind(this))
  }

  disconnect() {
    window.removeEventListener('navbar-height-changed', this.handleLayoutChange.bind(this))
    window.removeEventListener('resize', this.handleLayoutChange.bind(this))
    window.removeEventListener('popstate', this.handlePopState.bind(this))
  }

  handleLayoutChange() {
    this.updateHeaderOffset()
    this.updateTableBodyPadding()
    this.setGridTemplateColumns()
  }

  updateHeaderOffset() {
    const navbar = document.querySelector('[data-controller="navbar"]')
    const adminBanner = document.querySelector('.admin-banner')
    const adminBannerHeight = adminBanner ? adminBanner.offsetHeight : 0
    const navbarHeight = navbar ? navbar.getBoundingClientRect().height : 0
    const totalOffset = adminBannerHeight + navbarHeight

    // Set the top offset for the sticky header
    this.headerTarget.style.top = `${totalOffset}px`
  }

  updateTableBodyPadding() {
    const headerHeight = this.headerTarget.offsetHeight
    this.element.style.setProperty('--sticky-header-height', `${headerHeight}px`)
  }

  setGridTemplateColumns() {
    const listingsCount = this.listingsCountValue
    const firstColumnWidth = 200
    const minColumnWidth = 360

    const availableWidth = window.innerWidth - firstColumnWidth
    let desiredColumnWidth = availableWidth / listingsCount

    if (desiredColumnWidth < minColumnWidth) {
      desiredColumnWidth = minColumnWidth
    }

    let gridTemplateColumns = `${firstColumnWidth}px `
    for (let i = 0; i < listingsCount; i++) {
      gridTemplateColumns += `${desiredColumnWidth}px `
    }

    this.headerTarget.style.gridTemplateColumns = gridTemplateColumns
    this.bodyTarget.style.gridTemplateColumns = gridTemplateColumns
  }

  onScroll(event) {
    const scrollLeft = event.target.scrollLeft
    this.headerTarget.style.transform = `translateX(-${scrollLeft}px)`
  }

  handlePopState() {
    const currentParams = new URLSearchParams(window.location.search);
    const compareIds = currentParams.get('compare_ids');

    if (!compareIds) {
      // Exiting comparison mode via back button
      this.exitComparisonMode(currentParams);
    } else {
      // Re-entering comparison mode or changing comparison
      this.loadComparisonView(currentParams);
    }
  }

  exitComparisonMode(currentParams) {
    fetch(`/hybrids/filter?${currentParams.toString()}&exiting_comparison=true`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
    });
  }

  loadComparisonView(currentParams) {
    fetch(`/hybrids/filter?${currentParams.toString()}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
    });
  }

  onExitComparisonMode(event) {
    event.preventDefault();

    const url = new URL(window.location.href);
    const currentParams = new URLSearchParams(url.search);
    currentParams.delete('compare_ids');

    // Update URL first
    window.history.pushState({}, '', `${window.location.pathname}?${currentParams.toString()}`);

    // Then exit comparison mode
    this.exitComparisonMode(currentParams);
  }

}