import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'
import Cookies from 'js-cookie'
import debounce from "~/lib/debounce"

export default class extends Controller {
  static targets = ["input", "results"]

  initialize() {
    this.triggerSearch = debounce(this.triggerSearch.bind(this), 500);
  }

  async triggerSearch() {
    const query = this.inputTarget.value
    if (!query) {
      this.clearResults();
      return;
    }

    const request = new FetchRequest('GET', `/customers/search?q=${query}`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const customers = await response.json
      console.log('customers', customers)
      this.displayResults(customers)
    } else {
      this.displayError()
    }
  }

  displayResults(customers) {
    if (customers.length === 0) {
      this.resultsTarget.innerHTML = `<div class="p-2 text-sm text-gray-500">No customers found</div>`;
      return;
    }

    this.resultsTarget.innerHTML = customers.map(customer =>
      `<div class="p-2 hover:bg-gray-100 cursor-pointer" data-action="click->customer-search#selectCustomer" data-customer-id="${customer.id}" data-customer="${encodeURIComponent(JSON.stringify(customer))}">
        ${customer.first_name} ${customer.last_name} - ${customer.phone_number} - ${customer.company_name} - ${customer.email} - <a href="https://api.covercropexchange.com/admin/customers/${customer.id}" target="_blank" style="text-decoration: underline;" class="min-w-[100px]">View</a>
      </div>`
    ).join('');

    this.resultsTarget.classList.remove('hidden')
  }

  selectCustomer(event) {
    const customer = JSON.parse(decodeURIComponent(event.currentTarget.dataset.customer));
    console.log('customer', customer)

    this.inputTarget.value = event.currentTarget.textContent.trim();
    this.clearResults();

    Cookies.set('admin_selected_customer', JSON.stringify(customer))

    this.resultsTarget.classList.add('hidden');

    if (document.getElementById('zipcode_filter')) {
      const order = customer.most_recent_order
      document.getElementById('zipcode_filter').value = order.shipping_zipcode
      let element = document.getElementById('zipcode_filter')
      let event = new Event('keyup');
      element.dispatchEvent(event);
    }

    if (document.getElementById('shipping_request_destination_zipcode')) {
      const order = customer.most_recent_order
      document.getElementById('shipping_request_destination_zipcode').value = order.shipping_zipcode
    }

    if (document.getElementById('order_billing_name')) {
      const order = customer.most_recent_order
      let orderDetails = `Customer Page Link <a href="https://api.covercropexchange.com/admin/customers/${customer.id}" style="text-decoration: underline;" target="_blank">here</a><br>`;
      orderDetails += `Order Id: <a href="https://api.covercropexchange.com/admin/orders/${order.id}" style="text-decoration: underline;" target="_blank">${order.id}</a><br>`;
      orderDetails += `Seed Treated?: ${order.untreated_choice ? 'No' : 'Yes'}<br>`;
      orderDetails += `Organic?: ${order.certified_organic_market ? 'Yes' : 'No'}<br>`;
      orderDetails += `High Purity Market: ${order.non_gmo_purity_selection == "false" ? 'No' : 'Yes/Maybe?'}<br>`;
      orderDetails += `Notes: ${order.notes ? order.notes : ''}<br>`;
      orderDetails += `Email: ${order.email}<br>`;
      orderDetails += `Phone: ${order.phone_number}<br>`;
      orderDetails += `Billing Name: ${order.billing_name}<br>`;
      orderDetails += `Company Name: ${order.company_name}<br>`;
      orderDetails += `Billing Address: ${order.billing_address}<br>`;
      orderDetails += `Billing City: ${order.billing_city}<br>`;
      orderDetails += `Billing State: ${order.billing_state}<br>`;
      orderDetails += `Billing Zipcode: ${order.billing_zipcode}<br>`;
      orderDetails += `Shipping Same as Billing?: ${order.shipping_same_as_billing}<br>`;
      orderDetails += `Shipping Name: ${order.shipping_name}<br>`;
      orderDetails += `Shipping Address: ${order.shipping_address}<br>`;
      orderDetails += `Shipping City: ${order.shipping_city}<br>`;
      orderDetails += `Shipping State: ${order.shipping_state}<br>`;
      orderDetails += `Shipping Zipcode: ${order.shipping_zipcode}<br>`;
      orderDetails += `Payment Method: ${order.payment_method}`;
      document.getElementById('billing-previous-order-data').innerHTML = orderDetails
      document.getElementById('billing-previous-order-data').classList.remove('hidden')
      document.getElementById('order_email').value = order.email
      document.getElementById('order_email_confirmation').value = order.email
      document.getElementById('order_phone_number').value = order.phone_number
      document.getElementById('order_billing_name').value = order.billing_name
      document.getElementById('order_company_name').value = order.company_name
      document.getElementById('order_billing_address').value = order.billing_address
      document.getElementById('order_billing_city').value = order.billing_city
      document.getElementById('order_billing_state').value = order.billing_state
      document.getElementById('order_billing_zipcode').value = order.billing_zipcode
      if (order.shipping_same_as_billing) {
        document.getElementById('order_shipping_same_as_billing').checked = true
        let element = document.getElementById('order_shipping_same_as_billing');
        let event = new Event('change');
        element.dispatchEvent(event);
      } else {
        document.getElementById('order_shipping_name').value = order.shipping_name
        document.getElementById('order_shipping_address').value = order.shipping_address
        document.getElementById('order_shipping_city').value = order.shipping_city
        document.getElementById('order_shipping_state').value = order.shipping_state
        document.getElementById('order_shipping_zipcode').value = order.shipping_zipcode
      }
    }
  }

  clearResults() {
    this.resultsTarget.innerHTML = ''
  }

  displayError() {
    this.resultsTarget.innerHTML = `<div class="p-2 text-sm text-red-500">Error loading customers</div>`;
  }
}
