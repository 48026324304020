import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ["Menu", "Container", "LogoImage"]

  connect() {
    this.element[this.identifier] = this

    this.adminBanner = document.querySelector('.admin-banner')
    this.loggedInAdmin = !!this.adminBanner
    this.alreadyWhite = this.element.classList.contains("bg-white")
    this.io = new IntersectionObserver(this.observerCallback.bind(this), {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    })
    const mainContent = document.querySelector('#navbar-scroll-target')
    this.io.observe(mainContent)
  }

  observerCallback(entries) {
    const entry = entries[0]
    const { isIntersecting } = entry
    const adminBannerHeight = this.adminBanner ? this.adminBanner.offsetHeight : 0

    if (!this.alreadyWhite) {
      this.element.classList.toggle("!bg-white", !isIntersecting)
      this.element.classList.toggle("shadow", !isIntersecting)
    }

    if (!isIntersecting) {
      // Shrink Navbar
      this.ContainerTarget.classList.add("py-1", "sm:py-2")
      this.ContainerTarget.classList.remove("py-2", "sm:py-4")
      this.LogoImageTarget.classList.add("h-10", "w-24", "sm:w-32", "lg:w-30")
      this.LogoImageTarget.classList.remove("w-36", "sm:w-48", "lg:w-60")
      this.element.classList.add("lg:!z-50")
      this.element.classList.add('shrunk') // Add class to indicate shrunk state

      // Adjust the top position
      this.element.style.top = `${adminBannerHeight}px`

      // Update z-index to stay above the admin banner
      this.element.style.zIndex = '9998'

      // Delay dispatching the event to after the transition
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('navbar-height-changed'))
      }, 300) // Match the transition duration
    } else {
      // Expand Navbar
      this.ContainerTarget.classList.remove("py-1", "sm:py-2")
      this.ContainerTarget.classList.add("py-2", "sm:py-4")
      this.LogoImageTarget.classList.remove("h-10", "w-24", "sm:w-32", "lg:w-30")
      this.LogoImageTarget.classList.add("w-36", "sm:w-48", "lg:w-60")
      this.element.classList.remove('shrunk') // Remove shrunk class

      // Adjust the top position
      this.element.style.top = `${adminBannerHeight}px`

      // Reset z-index if needed
      this.element.style.zIndex = '9998'

      // Delay dispatching the event to after the transition
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('navbar-height-changed'))
      }, 300)
    }
  }

  toggleMenu() {
    const mainContainer = document.querySelector("#main-container")
    const isDesktop = window.matchMedia("only screen and (min-width: 1024px)").matches
    const shouldAddPadding = window.scrollY <= this.MenuTarget.offsetHeight && !isDesktop

    if (this.MenuTarget.classList.contains('hidden')) {
      enter(this.MenuTarget)
      if (shouldAddPadding) {
        mainContainer.style.paddingTop = `${this.MenuTarget.offsetHeight}px`
      }
    } else {
      leave(this.MenuTarget)
      mainContainer.style.paddingTop = `0px`
    }
  }
}
