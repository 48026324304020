import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.disable)
  }

  disable(event) {
    setTimeout(() => {
      event.target.disabled = true
    }, 0)
  }
}