import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["Button", "HiddenContent"]

  onExpand(e) {
    this.HiddenContentTarget.classList.remove("hidden")
    this.ButtonTarget.classList.add("!hidden")
  }

}