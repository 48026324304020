import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    tableId: String,
    rowFilterIds: Array,
  }

  static targets = ["stateSelectInput", "yearSelectInput"]

  yieldMapController() {
    var div = document.getElementById("yield-map-container")
    return this.application.getControllerForElementAndIdentifier(div, "yield-map")
  }

  connect() {
    this.table = document.getElementById(this.tableIdValue)
    this.rows = Array.from(this.table.querySelectorAll('tr[data-row-filter-state]'))
  }

  titleizedRowFilterIdValue() {
    return this.rowFilterIdValue.charAt(0).toUpperCase() + this.rowFilterIdValue.slice(1)
  }

  updateStateSelected(state) {
    this.stateSelectInputTarget.value = state
    this.updateTable()
  }

  onFilterChange(e) {
    e.preventDefault()

    this.updateTable()
  }

  updateTable() {
    window.requestAnimationFrame(() => {
      const stateFilter = this.stateSelectInputTarget.value;
      const yearFilter = this.yearSelectInputTarget.value;

      this.rows.forEach((row) => {
        const matchesState = stateFilter === "" || row.dataset.rowFilterState === stateFilter;
        const matchesYear = yearFilter === "" || row.dataset.rowFilterYearGrown === yearFilter;

        if (matchesState && matchesYear) {
          row.classList.remove("hidden");
        } else {
          row.classList.add("hidden");
        }
      });

      this.yieldMapController().updateStateSelected(stateFilter);
      this.yieldMapController().updateYearGrownSelected(yearFilter);

      this.setQueryString(stateFilter);
    });
  }

  setQueryString(state) {
    if (state !== "") {
      window.history.replaceState({}, document.title, "/results?yield_state=" + state);
    } else {
      window.history.replaceState({}, document.title, "/results");
    }
  }

}
